import { RequestTaskActionProcessDTO, RequestTaskDTO } from 'pmrv-api';

export const cancelActionMap: Partial<
  Record<RequestTaskDTO['type'], RequestTaskActionProcessDTO['requestTaskActionType']>
> = {
  PERMIT_NOTIFICATION_APPLICATION_SUBMIT: 'PERMIT_NOTIFICATION_CANCEL_APPLICATION',
  PERMIT_VARIATION_APPLICATION_SUBMIT: 'PERMIT_VARIATION_CANCEL_APPLICATION',
  PERMIT_VARIATION_REGULATOR_LED_APPLICATION_SUBMIT: 'PERMIT_VARIATION_CANCEL_APPLICATION',
  PERMIT_REVOCATION_APPLICATION_SUBMIT: 'PERMIT_REVOCATION_CANCEL_APPLICATION',
  PERMIT_SURRENDER_APPLICATION_SUBMIT: 'PERMIT_SURRENDER_CANCEL_APPLICATION',
  PERMIT_TRANSFER_A_APPLICATION_SUBMIT: 'PERMIT_TRANSFER_CANCEL_APPLICATION',
  PERMIT_TRANSFER_B_APPLICATION_SUBMIT: 'PERMIT_TRANSFER_CANCEL_APPLICATION',
  PERMIT_REVOCATION_WAIT_FOR_PEER_REVIEW: 'PERMIT_REVOCATION_CANCEL_APPLICATION',
  DRE_APPLICATION_SUBMIT: 'DRE_CANCEL_APPLICATION',
  DRE_WAIT_FOR_PEER_REVIEW: 'DRE_CANCEL_APPLICATION',
  DOAL_APPLICATION_SUBMIT: 'DOAL_CANCEL_APPLICATION',
  DOAL_WAIT_FOR_PEER_REVIEW: 'DOAL_CANCEL_APPLICATION',
  WITHHOLDING_OF_ALLOWANCES_APPLICATION_SUBMIT: 'WITHHOLDING_OF_ALLOWANCES_CANCEL_APPLICATION',
  WITHHOLDING_OF_ALLOWANCES_WAIT_FOR_PEER_REVIEW: 'WITHHOLDING_OF_ALLOWANCES_CANCEL_APPLICATION',
  RETURN_OF_ALLOWANCES_APPLICATION_SUBMIT: 'RETURN_OF_ALLOWANCES_CANCEL_APPLICATION',
  RETURN_OF_ALLOWANCES_WAIT_FOR_PEER_REVIEW: 'RETURN_OF_ALLOWANCES_CANCEL_APPLICATION',
  INSTALLATION_AUDIT_APPLICATION_SUBMIT: 'INSTALLATION_AUDIT_CANCEL_APPLICATION',
  INSTALLATION_ONSITE_INSPECTION_APPLICATION_SUBMIT: 'INSTALLATION_ONSITE_INSPECTION_CANCEL_APPLICATION',
};
